import type { PayloadAction } from '@reduxjs/toolkit/src/createAction'
import * as Sentry from '@sentry/react'
import type { Middleware } from 'redux'

export const sentryMiddleware: Middleware = () => (next) => (action: PayloadAction) => {
  Sentry.addBreadcrumb({
    category: 'redux',
    message: action.type,
    level: 'info',
  })

  return next(action)
}
