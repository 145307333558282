'use client'

import ReactDOM from 'react-dom'

const GOOGLE_STORAGE_DOMAIN = 'https://storage.googleapis.com'

export function PreloadResources(): null {
  // @ts-ignore
  ReactDOM.preconnect(GOOGLE_STORAGE_DOMAIN)

  // preload image for the LCP metric hack (LcpImage component)
  ReactDOM.preload('/images/transparent_500_700.png', { as: 'image' })

  return null
}
