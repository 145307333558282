import type { TypedThunk } from 'store'
import { openDialog } from 'store/modules/dialog/actions'
import { getGcGeneratorEnabledForUser } from 'store/modules/gcGenerator/selectors'
import { actions } from 'store/modules/gcGenerator/slice'
import type { AcceptFreeGoldCoinsRequest, GetFreeGoldCoinsStatusResponse } from 'store/modules/gcGenerator/types'

export const {
  setGcGeneratorSettings,
  setGcGeneratorAccepting,
  setGcGeneratorAccepted,
  setGcGeneratorEnabled,
  setGcGeneratorLoading,
} = actions

export const requestGoldCoinsGenerator =
  (): TypedThunk =>
  async (dispatch, getState, { gateway }) => {
    const isGcGeneratorEnabledForUser = getGcGeneratorEnabledForUser(getState())

    if (!isGcGeneratorEnabledForUser) {
      return
    }

    try {
      dispatch(setGcGeneratorLoading({ loading: true }))
      const body = await gateway.emit<GetFreeGoldCoinsStatusResponse>({ type: 'GetFreeGoldCoinsStatusRequest' })
      const { accepted, settings } = body
      dispatch(setGcGeneratorAccepted({ accepted }))
      dispatch(setGcGeneratorSettings(settings))
    } catch (error) {
      console.log(error)
      dispatch(setGcGeneratorSettings({ interval: 0, amount: 0 }))
    } finally {
      dispatch(setGcGeneratorLoading({ loading: false }))
    }
  }

export const requestGoldCoinsGeneratorAccept =
  (): TypedThunk =>
  async (dispatch, getState, { gateway }) => {
    const isGcGeneratorEnabledForUser = getGcGeneratorEnabledForUser(getState())
    if (!isGcGeneratorEnabledForUser) {
      return
    }

    try {
      dispatch(setGcGeneratorAccepting({ accepting: true }))
      const data: AcceptFreeGoldCoinsRequest = {
        type: 'AcceptFreeGoldCoinsRequest',
      }
      await gateway.emit(data)
      await requestGoldCoinsGenerator()(dispatch, getState, null)
      dispatch(openDialog({ modalName: 'GOLD_COIN_GENERATOR_SUCCESSFUL_DIALOG' }))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(setGcGeneratorAccepting({ accepting: false }))
    }
  }
