import type { ActionTypeMapFn } from '@patrianna-payments/shared-store/payments/store'
import {
  buyShopOfferActionName,
  createOrderResponseCardPaymentErrorHandlerActionName,
  createOrderResponseCardQuickPurchasePaymentErrorHandlerActionName,
  finishedPaymentFlowActionName,
  finishedQuickPurchasePaymentFlowActionName,
  getOffersActionName,
  offerDeclineNotificationHandlerActionName,
  offerDeclineTrustlyHandlerActionName,
  offerPurchaseSuccessActionName,
  openPaymentProvidersDialogActionName,
  paymentErrorsHandlerActionName,
  purchaseResponseListenerByRouterActionName,
  quickPurchasePaymentErrorsHandlerActionName,
  sentryCaptureMessageActionName,
  sentryCaptureExceptionActionName,
  receiveSoftKycOptionsActionName,
  start3dsFlowActionName,
} from '@patrianna-payments/shared-store/payments/store/middleware-actions'
import type { AnyAction } from '@reduxjs/toolkit'

import {
  handle3dsFlowStart,
  handleBuyShopOfferAction,
  handleCreateOrderResponseCardPaymentErrorHandlerAction,
  handleCreateOrderResponseCardQuickPurchasePaymentErrorHandlerAction,
  handleFinishedPaymentFlowAction,
  handleFinishedQuickPurchasePaymentFlowAction,
  handleOfferDeclineNotificationHandlerAction,
  handleOfferDeclineTrustlyHandlerAction,
  handleOfferPurchaseSuccessAction,
  handleOpenPaymentProvidersDialogAction,
  handlePaymentErrorsHandlerAction,
  handlePurchaseResponseListenerByRouterAction,
  handleQuickPurchasePaymentErrorsHandlerAction,
} from 'store/middlewares/handlers/payments'
import { handleSentryCaptureException, handleSentryCaptureMessage } from 'store/middlewares/handlers/sentry'
import type { MiddlewareType } from 'store/middlewares/types'
import { saveSoftKYCOptions } from 'store/modules/appConfig/actions'
import { getOffers } from 'store/modules/shop/actions'

const actionsMap: Record<string, ActionTypeMapFn> = {
  [finishedPaymentFlowActionName]: handleFinishedPaymentFlowAction,
  [finishedQuickPurchasePaymentFlowActionName]: handleFinishedQuickPurchasePaymentFlowAction,
  [offerPurchaseSuccessActionName]: handleOfferPurchaseSuccessAction,
  [getOffersActionName]: getOffers,
  [paymentErrorsHandlerActionName]: handlePaymentErrorsHandlerAction,
  [quickPurchasePaymentErrorsHandlerActionName]: handleQuickPurchasePaymentErrorsHandlerAction,
  [purchaseResponseListenerByRouterActionName]: handlePurchaseResponseListenerByRouterAction,
  [offerDeclineTrustlyHandlerActionName]: handleOfferDeclineTrustlyHandlerAction,
  [offerDeclineNotificationHandlerActionName]: handleOfferDeclineNotificationHandlerAction,
  [buyShopOfferActionName]: handleBuyShopOfferAction,
  [createOrderResponseCardPaymentErrorHandlerActionName]: handleCreateOrderResponseCardPaymentErrorHandlerAction,
  [createOrderResponseCardQuickPurchasePaymentErrorHandlerActionName]:
    handleCreateOrderResponseCardQuickPurchasePaymentErrorHandlerAction,
  [openPaymentProvidersDialogActionName]: handleOpenPaymentProvidersDialogAction,
  [sentryCaptureMessageActionName]: handleSentryCaptureMessage,
  [sentryCaptureExceptionActionName]: handleSentryCaptureException,
  [receiveSoftKycOptionsActionName]: saveSoftKYCOptions,
  [start3dsFlowActionName]: handle3dsFlowStart,
}

export const paymentsMiddleware: MiddlewareType =
  ({ dispatch }) =>
  (next) =>
  (action: AnyAction) => {
    if (action.type in actionsMap) {
      dispatch(actionsMap[action.type](action?.payload))
    }

    next(action)
  }
