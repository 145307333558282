import type { GetTemporaryTokenResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import { initPrizeoutSDK } from '@patrianna-payments/prizeout'
import { getCurrencyRateSelector } from '@patrianna-payments/shared-store/payments/store/selectors'
import { getPendingRedeemCountRequest } from '@patrianna-payments/shared-store/redeem/store/actions'
import {
  getPrizeoutDataSelector,
  getPrizeoutNonMonetaryRedeemPolicySelector,
} from '@patrianna-payments/shared-store/redeem/store/selectors'

import { getValueWithRate } from 'dialogs/RedeemFlow/utils'
import type { TypedThunk } from 'store'
import { getLocationDataSelector, getWithdrawProvidersSelector } from 'store/modules/appConfig/selectors'
import { getSweepstakeCurrencySelector } from 'store/modules/currencies/selectors'
import { closeLatestDialog } from 'store/modules/dialog/actions'
import { getUserSelector } from 'store/modules/user/selectors'

import { getPrizeoutStateSelector } from './selectors'

export const initPrizeout =
  (tokenData: GetTemporaryTokenResponse): TypedThunk =>
  (dispatch, getState) => {
    const withdrawProvidersFromPayments = getWithdrawProvidersSelector(getState())
    const prizeoutData = getPrizeoutDataSelector(getState(), withdrawProvidersFromPayments)
    const location = getLocationDataSelector(getState())
    const user = getUserSelector(getState())
    const currencyBalance = getSweepstakeCurrencySelector(getState())
    const prizeOutNonMonetaryRedeemPolicy = getPrizeoutNonMonetaryRedeemPolicySelector(getState())
    const prizeoutRegion = getPrizeoutStateSelector(getState())
    const currencyRate = getCurrencyRateSelector(getState())

    if (!user || !location || !prizeOutNonMonetaryRedeemPolicy || !prizeoutData?.apiKey || !currencyBalance) {
      return null
    }

    initPrizeoutSDK({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.realEmail,
      userId: tokenData.subject,
      sessionId: tokenData.token,
      country: location.country,
      region: prizeoutRegion,
      nonMonetaryRedeemPolicy: prizeOutNonMonetaryRedeemPolicy,
      redeemableCurrencyBalance: getValueWithRate({ amount: currencyBalance?.redeemable, rate: currencyRate?.rate }),
      prizeoutId: prizeoutData?.merchantName,
      prizeoutKey: prizeoutData?.apiKey,
      dispatch,
      closeLatestDialog,
      getPendingRedeemCountRequest,
      publisherName: 'B2Spin',
    })
  }
