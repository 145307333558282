import type { PubSubDataChannel } from '@patrianna-payments/pub-sub/src/lib/core'
import { combineReducers, configureStore, createListenerMiddleware } from '@reduxjs/toolkit'
import type { UnknownAction, ThunkAction, Action, Middleware } from '@reduxjs/toolkit'

import type { ServiceDependenciesOptions } from 'services'
import { serviceDependencies } from 'services'
import { pubSubManager } from 'services/pubsub_con'
import { paymentsMiddleware } from 'store/middlewares/paymentsMiddleware'
import { redeemMiddleware } from 'store/middlewares/redeemMiddleware'
import { sentryMiddleware } from 'store/middlewares/sentryMiddleware'
import { verificationsMiddleware } from 'store/middlewares/verificationsMiddleware'

import { authMiddleware } from './middlewares/authMiddleware'
import rootReducers from './modules/rootReducer'

//[MF] channels listeners
import './channels'

const defaultReduxMiddlewareOptions = {
  thunk: {
    extraArgument: serviceDependencies,
  },
}

const listenerMi = createListenerMiddleware()

const channelCache: Record<string, PubSubDataChannel<unknown>> = {}

listenerMi.startListening({
  predicate: (action) =>
    action.type.includes('user') ||
    action.type.includes('appConfig') ||
    action.type.includes('currencies') ||
    action.type.includes('dialog'),
  effect: (action, listenerApi) => {
    const [reducerName] = action?.type?.split('/')

    let channel = channelCache[reducerName]

    if (!channel) {
      channel = pubSubManager.createDataChannel(reducerName, null)
      channelCache[reducerName] = channel
      channel.open()
    }

    const store: Record<string, unknown> = listenerApi.getState() as Record<string, unknown>

    channel.update(store[reducerName])
  },
})

// custom middlewares array
const middlewares: Middleware[] = [
  listenerMi.middleware,
  verificationsMiddleware,
  redeemMiddleware,
  authMiddleware,
  paymentsMiddleware,
  sentryMiddleware,
]

const setupStore = () => {
  const store = configureStore({
    reducer: combineReducers(rootReducers),
    middleware: (getDefaultMiddleware) =>
      /*
        With this configuration, we can use "serviceDependencies" in each thunk and access to our API via third argument

        Example:
        const getAccountInfo = () => (dispatch, getState, { gateway }) => {
          gateway.emit<GetAccountInfoRequest>({ type: 'GetAccountInfoRequest' })
        }

        Docs: https://redux-toolkit.js.org/api/getDefaultMiddleware
      */
      getDefaultMiddleware(defaultReduxMiddlewareOptions).concat(middlewares),
    devTools: process.env.PROFILE === 'dev',
  })

  return store
}

export default setupStore()

// Infer the type of makeStore
type AppStore = ReturnType<typeof setupStore>
// Infer the `ReduxState` and `AppDispatch` types from the store itself
type ReduxState = ReturnType<AppStore['getState']>

type AppDispatch = AppStore['dispatch']

type TypedThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ReduxState,
  ServiceDependenciesOptions,
  UnknownAction | Action
>

export type { AppStore, AppDispatch, ReduxState, TypedThunk }
