'use client'

import { TranslationsProvider } from '@patrianna/core-components'
import { UnSupportedBrowser } from '@patrianna/core-components/src/components/UnSupportedBrowser'
import { DetectMobileProvider } from '@patrianna/core-components/src/context/mobileDetectionContext'
import { decode } from '@patrianna/core-components/src/context/translations/utils/encryption'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { Provider } from 'react-redux'

import { AuthProvider } from 'app/context/auth'
import { CountryConfigProvider } from 'app/context/country'
import { NavigationProvider } from 'app/context/navigation'
import type { AuthCookies } from 'app/utils/getAuthCookies'
import store from 'store'

type Props = {
  children: ReactNode
  translations: any
  authCookies: AuthCookies
  isMobile: boolean
  countryCode: string
  prismicDynamicValuesConfig: Record<string, string>
}

export function Providers({
  children,
  translations,
  authCookies,
  isMobile,
  countryCode,
  prismicDynamicValuesConfig,
}: Props) {
  useEffect(() => {
    const decodedTranslation = decode(translations)
    // TODO: temporary solution for handling translation keys within validator utility. Need to rework this.
    // @ts-ignore
    window.__FORM_VALIDATION_TRANSLATIONS__ = {
      formValidators: decodedTranslation?.formValidators,
      verification: decodedTranslation?.pageIdentityVerification,
    }
  }, [translations])

  return (
    <UnSupportedBrowser>
      <DetectMobileProvider isMobile={isMobile}>
        <Provider store={store}>
          <CountryConfigProvider countryCode={countryCode}>
            <TranslationsProvider
              translations={translations}
              prismicDynamicValuesConfig={prismicDynamicValuesConfig}
              shouldDecodeTranslations
            >
              <AuthProvider authCookies={authCookies}>
                <NavigationProvider>{children}</NavigationProvider>
              </AuthProvider>
            </TranslationsProvider>
          </CountryConfigProvider>
        </Provider>
      </DetectMobileProvider>
    </UnSupportedBrowser>
  )
}
